.dropdown {
    box-sizing: border-box;
    cursor: pointer;
    background-color: #fff;
    user-select: none;
    border: 1px solid #ced4da;
    border-right: none;
    height: 38px;
    width: 100%;
    color: #495057;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    
    &:disabled {
        color: rgb(170, 170, 170);
        background-color: rgb(228, 228, 228);
        border-color: rgba(118, 118, 118, 0.3);
    }
}

.dropdown__wrapper {}

.input__wrapper {
    display: flex;
    align-items: center;
}


.dropdown__arrow {
    padding: 6px 6px 6px 0;
    padding-left: 0;
    background-color: #fff;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border: 1px solid #ced4da;
    border-left: none;

    svg {
        fill: #495057;
    }

    &.disabled svg {
        color: rgb(170, 170, 170);
    }

    &.disabled {
        background-color: rgb(228, 228, 228);
        border-color: rgba(118, 118, 118, 0.3);
    }
} 


.popper__element {
    z-index: 99;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, .2);
}

.dropdown__list {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 0;
    margin: 0;
}

.dropdown__search__wrapper {
    padding: 8px;
}

.dropdown__item {
    background: #fff;
    width: 100%;
    padding: 4px 8px;
    cursor: pointer;
    transition: background 0.5s ease;

    &:hover {
        background: gainsboro;
    }
}

.dropdown__item__search {
    margin: 4px;
}

.dropdown__options {
    // config how much items viewed
    max-height: calc(32px * 5);
    overflow-y: auto;
}