.error {
  color: red;
}

.success {
  color: green;
}

.invite-student-form {
  display: flex;
  margin-top: 20px;
  width: 100%;
}

.invite-student-form {
  width: 400px;
}

.invite-student-form input[type=checkbox] {
  width: 25px;
  margin-left: 15px ;
}


.col-common {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.column {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.row-common {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
