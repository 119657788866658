.chief-review-action-panel {
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;
  gap: 20px;
}

.chief-review-action-panel .review-filter-selector {
  width: 150px;
  margin-top: 0 !important;
}

.chief-review-action-panel .review-filter-checkbox-wrapper {
  height: calc(1.5em + 0.75rem + 2px);
width: auto;
}

.chief-review-action-panel-btn {
  margin-top: 1em;
}
