.meet-plan-modal {
  &__content {
    padding: 0 20px;
  }

  &__text {
    width: 150px;
    margin-bottom: .5rem;
  }

  &__input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  &__input-date {
    font-size: .875rem !important;
    width: auto;
  }

  &__input-time {
    width: auto;
    font-weight: normal;
    font-size: .875rem !important;
    padding: .375rem .75rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  &__link {
    display: flex;
    max-height: 38px;
    margin: auto .25rem auto 0;
    align-items: center;
    justify-content: center;
    color: white;
    background: #007bff;
    display: inline-block;
    font-weight: 400;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:hover {
      color: white;
      background: #026de0;
    }
  }
}
