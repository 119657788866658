.pagination-search-input {
    margin: 0;
    z-index: 0 !important;
  }

.pagination-search-addon {
    padding: 6px 10px !important;
    width: 4rem;
    margin: 0 -4px;
    height: initial !important;
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
    color: #404040 !important;
    background-color: #e5e5e5 !important;
  }
.pagination-search-input{
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.pagination-search-group{
    display: flex;
    flex-direction: row;
}
.pagination-glyphicon-search::before{
    content: "🔎";
}
.pagination-csv-button {
  margin-bottom: 1rem;
}
