.commentButton {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
    &:hover {
        text-decoration: underline;
    }
}
