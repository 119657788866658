.meet-plan-filters {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 2rem 0;

  &__label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    cursor: pointer;
  }

  &__text {
    display: block;
    text-decoration: underline;
    margin-bottom: 0.5rem;
  }

  &__checkbox {
    margin-right: 0.5rem;
  }

  &__button {
    margin: 0!important;
  }

  &__date-picker {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__date-btn {
    border: none;
    background: #CED4DA;
    color: #495057;
    transition: all .2s ease-in;
    padding: 8px 10px;

    &:hover {
      background: #3988FF;
      color: #FFFFFF;
    }
  }

  &__month {
    min-width: 150px;
    padding: 10px;
    margin: 0;
    background: #FFFFFF;
    text-transform: capitalize;
  }

  &__prev-btn {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &__next-btn {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
