.input-file {
    position: relative;
    display: inline-block;
	margin: 24px 0;
}

.input-file.loading {
	pointer-events: none;
	user-select: none;
	opacity: 0.5;
}

.input-file-btn {
	position: relative;
}
.input-file-text {
	padding: 0 10px;
	line-height: 40px;
	display: inline-block;
}
.input-file input[type=file] {
	position: absolute;
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
}
